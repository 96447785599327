import { useRxTaxExemption } from '@foundation/hooks/useRxTaxExemption'
import { ContactLensesData } from '@typesApp/product'

type IsNonPrescriptionColoredLensParams = {
  contactLensesData: ContactLensesData
  isColorContacts: boolean
}

export function checkIsNonPrescriptionColoredLens(params: IsNonPrescriptionColoredLensParams) {
  const { contactLensesData, isColorContacts } = params

  const rxTaxExemption = useRxTaxExemption()
  const isNonPrescription = ['left', 'right'].reduce((acc, side) => {
    const lensSide = contactLensesData[side]

    if (lensSide.fieldsStatus?.enabled === false) {
      return acc
    }

    const isNonePrescription = ['+0.00'].includes(lensSide.x_spherePower ?? '')

    return acc && isNonePrescription
  }, true)

  return isColorContacts && isNonPrescription && rxTaxExemption.isEnabled
}
