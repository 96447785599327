import { ALGOLIA_FILTERS } from '@foundation/constants/common'
import { formatDiscountQuery } from '@utils/url'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

const getPlpUrl = (): string => {
  return sessionStorage.getItem(ALGOLIA_FILTERS) ?? ''
}

const formatUrl = (url: string): string => {
  const [baseUrl, queryString] = url.split('?')
  if (queryString) {
    const queries = queryString.split('&')
    const formattedQueries = queries.map(formatDiscountQuery)
    return `${baseUrl}?${formattedQueries.join('&')}`
  }

  return url
}

export const useNavigateBack = () => {
  const router = useRouter()
  const [isBackNavigation, setIsBackNavigation] = useState(false)

  useEffect(() => {
    const handlePopState = () => {
      setIsBackNavigation(true)
    }

    if (typeof window !== 'undefined') window.addEventListener('popstate', handlePopState)

    return () => {
      if (typeof window !== 'undefined') window.removeEventListener('popstate', handlePopState)
    }
  }, [])

  useEffect(() => {
    if (isBackNavigation && typeof window !== 'undefined') {
      const plpUrl = getPlpUrl()

      if (plpUrl === window.location.pathname) return

      const formattedUrl = formatUrl(plpUrl)
      sessionStorage.removeItem(ALGOLIA_FILTERS)
      router.replace(formattedUrl)
    }
  }, [isBackNavigation, router])
}
