import { Button } from '@components/UI/Button'
import { Pill } from '@components/UI/Pill'
import { GridContainer, GridItem } from '@components/UI/Grid'
import ProductImagesSlider from './components/ProductImagesSlider/ProductImagesSlider'
import Typography from '@mui/material/Typography'
import styled from '@mui/material/styles/styled'
import { Link } from '@components/common/Link/Link'
import { gridColumns } from '../../constants/ui'

const PdpContainer = styled(GridContainer, {
  name: 'PdpContainer',
  slot: 'MainContainer',
})(({ theme }) => ({
  position: 'relative', //to check
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  minHeight: '100vh',

  gridTemplateColumns: `repeat(${gridColumns['xs']}, 1fr)`,

  '& > div:nth-of-type(5)': {
    width: '100%',
    backgroundColor: theme.palette.background.light.secondary,
  },

  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),

    '.pdp-container__breadcrumbs': {
      gridColumn: 'span 12',
      marginLeft: 0,
    },
  },

  '#vmPortal': {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
  },
}))

const TrustPilotMiniWidgetContainer = styled('div', {
  name: 'TrustPilotMiniWidget',
  slot: 'Container',
})(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingLeft: theme.spacing(4),
  height: '40px',
}))

const PdpLeftColumn = styled(GridItem, {
  name: 'PdpContainer',
  slot: 'LeftColumn',
})(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    height: 'auto',
  },
}))

const StyledTitleContent = styled('div', {
  name: 'PdpContainer',
  slot: 'Title',
})(() => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}))

const StyledProductName = styled(Typography, {
  name: 'PdpContainer',
  slot: 'ProductName',
})(() => ({}))

const StyledSeparator = styled('hr', {
  name: 'ProductDetails',
  slot: 'Separator',
})(({ theme }) => ({
  height: 1,
  margin: 0,
  border: 'none',
  alignSelf: 'stretch',
  flexGrow: 0,
  backgroundColor: theme.palette.background.light.tertiary,
}))

export const StyledContainer = styled('div', {
  name: 'PdpContainer',
  slot: 'Enabler',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: theme.spacing(4),
  margin: 'auto',
  paddingTop: '16px',

  [theme.breakpoints.up('sm')]: {
    paddingTop: 0,
  },

  [theme.breakpoints.up('md')]: {
    paddingLeft: '80px',
  },
}))

export const StyledSectionTitle = styled('div', {
  name: 'PdpContainer',
  slot: 'SectionTitle',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  textTransform: 'uppercase',
  fontWeight: 600,
  fontSize: 14,
  lineHeight: 1.43,
}))

export const StyledSwitcherContainer = styled('div', {
  name: 'PdpContainer',
  slot: 'SwitcherContainer',
})(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
}))

export const Background = styled('div', {
  name: 'VirtualMirror',
  slot: 'Container',
})(({ theme }) => ({
  content: '""',
  height: '100vh',
  width: '100%',
  overflow: 'hidden',
  position: 'fixed',
  top: '0',
  left: '0',
  backgroundColor: theme.palette.background.dark.primary,
  zIndex: 9900,
}))

export const VMContainer = styled('div', {
  name: 'VirtualMirror',
  slot: 'Container',
  shouldForwardProp: prop => prop === 'isOpen',
})<{ isOpen: boolean }>(({ theme, isOpen }) => ({
  backgroundColor: theme.palette.background.dark.primary,
  fontFamily: `${theme.typography.fontFamily} !important`,
  maxWidth: '768px',
  height: '100%',
  width: '100%',
  zIndex: 9999,
  position: 'fixed',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  margin: 'auto',
  '.is-vm-open': {
    body: {
      overflowY: isOpen ? 'hidden' : 'auto',
    },
  },
  '.vmmv-drawer-screenshot': {
    maxWidth: '48em',
    width: '100%',
    left: 0,
  },
  '.vmmv-screenshot-wrapper': {
    padding: '3em 10%',
    overflowY: 'auto',
    'img:nth-child(3)': {
      width: 'auto',
    },
  },
  '.swiper-slide': {
    width: '80px',
  },
  '[class^="styles__ActionBox-sc-"]': {
    maxWidth: '20%',
    fontWeight: '600 !important',
  },
  '[class^="styles__ActionBox-sc-"],[class^="styles__ProductInfo-sc-"],[class^="styles__TipDescription-sc-"], [class^="styles__ParagraphDescription-sc-"], [class^="styles__Title-sc-"], #bipa-component-container, #PRIVACY_IMPLICIT_TERMS_AND_CONDITIONS, #PRIVACY_IMPLICIT_AGREEMENT, #PRIVACY_IMPLICIT_AGREEMENT b, #PRIVACY_AGREEMENT_TITLE, #PRIVACY_FIRST_AGREEMENT, #privacy-agreement p span, #privacy-agreement p':
    {
      fontFamily: `${theme.typography.fontFamily} !important`,
      color: `${theme.palette.background.dark.primary} !important`,
    },
  '[class^="styles__ListText-sc-"]': {
    fontFamily: `${theme.typography.fontFamily} !important`,
  },
  '[class^="styles__Title-sc-"]': {
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'nowrap !important',
    },
  },
  '[class^="styles__SeeInstructions"]': {
    fontFamily: `${theme.typography.fontFamily} !important`,
  },
  '[class^="styles__StyledLabel"]': {
    fontFamily: `${theme.typography.fontFamily} !important`,
  },
  '[class^="loading-text"]': {
    fontFamily: `${theme.typography.fontFamily} !important`,
  },
  '[class^="styles__PrivacyNotice"]': {
    fontFamily: `${theme.typography.fontFamily} !important`,
  },
  '[class^="styles__ErrorLabel"]': {
    fontFamily: `${theme.typography.fontFamily} !important`,
  },
  '[class^="styles__ErrorMessage"]': {
    fontFamily: `${theme.typography.fontFamily} !important`,
  },
  '.upload-image-display-controls > .upload-image-display-accept': {
    fontFamily: `${theme.typography.fontFamily} !important`,
  },
  '.upload-image-display-controls > .upload-image-display-accept::after': {
    fontFamily: `${theme.typography.fontFamily} !important`,
  },
  '.upload-image-display-controls > .upload-image-display-decline': {
    fontFamily: `${theme.typography.fontFamily} !important`,
  },
  '.upload-image-display-controls > .upload-image-display-decline::after': {
    fontFamily: `${theme.typography.fontFamily} !important`,
    position: 'static !important',
  },
  '[class^="fa-nudging-guide"]': {
    fontFamily: `${theme.typography.fontFamily} !important`,
  },
  '[class^="styles__ButtonsContainer"]': {
    position: 'static !important',
    marginTop: 'auto',
  },
  '[class^="styles__Tip-sc"]': {
    '@media (min-width: 768px)': {
      marginTop: '1em',
    },
  },
  '[class^="styles__PictureModeEntryPageWraper-sc"]': {
    '[class^="Button__StyledButton-sc"]': {
      fontFamily: `${theme.typography.fontFamily} !important`,
      '@media only screen and (min-width: 768px)': {
        marginTop: '5em',
      },
    },
  },
  '#PRIVACY_AGREEMENT_TITLE': {
    margin: '0 !important',
  },
  '#bipa-submit-button span': {
    fontWeight: '600 !important',
  },
  '#bipa-cancel-button': {
    fontFamily: `${theme.typography.fontFamily} !important`,
    borderRadius: '0 !important',
    border: `1px solid ${theme.palette.background.dark.primary} !important`,
    color: `${theme.palette.background.dark.primary} !important`,
    textTransform: 'uppercase',
    '#PRIVACY_BUTTON_CANCEL': {
      fontWeight: '600 !important',
    },
  },
  '#bipa-submit-button, [class^="Button__StyledButton-sc-"], #upload-image-drop-zone, .upload-image-display-accept, [class^="styles__TryAgainButton-sc-"]':
    {
      fontFamily: `${theme.typography.fontFamily} !important`,
      backgroundColor: `${theme.palette.background.dark.primary} !important`,
      color: `${theme.palette.background.dark.secondary} !important`,
      borderRadius: '0 !important',
      textTransform: 'uppercase',
      fontWeight: '600 !important',
      '&:hover': {
        backgroundColor: `${theme.palette.background.dark.tertiary} !important`,
        borderColor: `${theme.palette.background.dark.tertiary} !important`,
      },
    },
  '.yes-no-checkbox .switch .slider': {
    backgroundColor: '#99ACB1 !important',
  },
  '.yes-no-checkbox .switch .slider.round::before': {
    backgroundColor: '#CDE9E4 !important',
  },
  '.yes-no-checkbox .switch input:checked + .slider': {
    backgroundColor: `${theme.palette.background.dark.secondary} !important`,
  },
  '.yes-no-checkbox .switch input:checked + .slider::before': {
    backgroundColor: `${theme.palette.background.dark.primary} !important`,
  },
  '[class^="styles__FooterInner"]': {
    '[class^="styles__IconWrapper"]': {
      background: theme.palette.color.grey,
      '&:hover': {
        backgroundColor: `${theme.palette.background.dark.tertiary} !important`,
        borderColor: `${theme.palette.background.dark.tertiary} !important`,
      },
    },
    '[class^="styles__FooterLabelWrapper"] span': {
      fontFamily: `${theme.typography.fontFamily} !important`,
      '[class^="styles__PriceStyled"]:nth-child(2)': {
        fontSize: '1em',
      },
    },
    '[class^="Button__StyledButton"] span': {
      fontFamily: `${theme.typography.fontFamily} !important`,
      background: theme.palette.background.dark.primary,
      border: `1px solid ${theme.palette.background.dark.primary}`,
      color: theme.palette.background.dark.secondary,
      borderRadius: 0,
      '&:hover': {
        backgroundColor: `${theme.palette.background.dark.tertiary} !important`,
        borderColor: `${theme.palette.background.dark.tertiary} !important`,
      },
    },
  },
  '[class^="styles__PictureModeEntryPageWraper-sc-"]': {
    '@media only screen and (min-width: 1224px)': {
      padding: '5em 7em 2em',
    },
  },
  '#upload-image-drop-zone::after, .upload-image-display-accept::after': {
    fontFamily: `${theme.typography.fontFamily} !important`,
    color: `${theme.palette.background.dark.secondary} !important`,
    fontWeight: '600 !important',
  },
  'button, [class^="styles__MenuLogo-sc-"], [class^="styles__CatalogItem-sc-"], [class^="styles__IconWrapper-sc-"], [class^="styles__ActionBtn-sc-"], [class^="styles__ActionBox-sc-"]>svg':
    {
      cursor: 'pointer',
      fill: theme.palette.background.dark.primary,
      stroke: `${theme.palette.background.dark.primary} !important`,
      strokeWidth: '0.5',
    },
  '#bipa-overlay-root-style.bipa-overlay #bipa-component-container button span': {
    fontFamily: `${theme.typography.fontFamily} !important`,
  },
  '#bipa-overlay-root-style.bipa-overlay b': {
    fontFamily: `${theme.typography.fontFamily} !important`,
  },
}))

export const VMWrapper = styled('div', {
  name: 'VirtualMirror',
  slot: 'Wrapper',
})(() => ({
  backgroundColor: '#000',
  inset: 0,
  opacity: 0.3,
  position: 'fixed',
  zIndex: 9998,
}))

const PdpRightColumn = styled(GridItem, {
  name: 'PdpContainer',
  slot: 'RightColumn',
})(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(13),
    paddingLeft: theme.spacing(10),
    borderLeft: `1px solid ${theme.palette.background.light.tertiary}`,
    width: '100%',
  },
}))

const StyledRightColumnContainer = styled('div', {
  name: 'PdpContainer',
  slot: 'RightColumnContainer',
  shouldForwardProp: prop => prop !== 'visible' && prop === 'children',
})<{
  visible?: boolean
}>(({ visible, theme }) => ({
  display: visible ? 'flex' : 'none',
  flexDirection: 'column',
  gap: theme.spacing(4),
  '& > span': {
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
    },
  },
}))

const StyledLeftColumnContainer = styled('div', {
  name: 'PdpContainer',
  slot: 'LeftColumContainer',
  shouldForwardProp: prop => prop !== 'visible' && prop === 'children',
})<{
  visible?: boolean
}>(({ visible, theme }) => ({
  display: visible ? 'flex' : 'none',
  flexDirection: 'column',
  width: '100%',

  [theme.breakpoints.up('md')]: {
    position: 'sticky',
    top: '12px',
    paddingBottom: '32px',
  },
}))

const VirtualMirrorWrapper = styled('div', {
  name: 'PdpContainer',
  slot: 'VirtualMirrorWrapper',
})(() => ({
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  position: 'sticky',
}))

const StyledButton = styled('div', {
  name: 'PdpContainer',
  slot: 'StyledButton',
})(({ theme }) => ({
  fontSize: theme.spacing(2.5),
  fontWeight: '600',
  textTransform: 'uppercase',
  color: theme.palette.text.dark.primary,
  backgroundColor: theme.palette.background.light.secondary,
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  border: `1px solid ${theme.palette.background.light.tertiary}`,
  borderRadius: theme.spacing(10),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(2),
  cursor: 'pointer',
  margin: `${theme.spacing(4)} 0`,

  [theme.breakpoints.up('md')]: {
    margin: '0',
  },
}))

const SoldOutImagePlaceholderContainer = styled('div', {
  name: 'PdpContainer',
  slot: 'SoldOutImagePlaceholder',
})(({ theme }) => ({
  background: theme.palette.background.light.tertiary,
  width: 672,
  height: 336,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: theme.spacing(8),
  marginRight: theme.spacing(19.5),
  marginTop: theme.spacing(21),
  padding: `${theme.spacing(18)} 0`,
}))

const SoldOutTitle = styled('div')(({ theme }) => ({
  fontSize: theme.spacing(10),
  color: theme.palette.text.light.secondary,
}))

const SoldOutDescription = styled('div')(({ theme }) => ({
  width: 304,
  fontSize: theme.spacing(5),
  color: theme.palette.text.light.secondary,
}))

const SliderWrapper = styled('div', {
  name: 'PdpDetails',
  slot: 'SliderWrapper',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignSelf: 'center',
  width: '100%',
  paddingTop: theme.spacing(4),
  height: '180px',
  marginBottom: '8px',

  [theme.breakpoints.between('sm', 'lg')]: {
    paddingTop: theme.spacing(0),
  },

  [theme.breakpoints.up('sm')]: {
    height: 'auto',
    marginBottom: 0,
  },
}))

const ProductFullPageGalleryThumbsSlider = styled(ProductImagesSlider, {
  name: 'ProductFullPageGallery',
  slot: 'ThumbsSlider',
})(({ theme, sliderImageProps }) => ({
  width: '100%',
  '.swiper': {
    padding: `0 ${theme.spacing(4)} ${theme.spacing(6)} ${theme.spacing(4)}`,
  },
  '.swiper-slide': {
    cursor: 'pointer',
    position: 'relative',
    width: `calc(${theme.spacing(20)} - 2px )`,
    height: `calc(${theme.spacing(14)} - 2px )`,
    border: `1px solid  ${theme.palette.text.light.primary}`,
    paddingBottom: '2px',
    '&:hover': {
      border: `1px solid  ${theme.palette.text.dark.tertiary}`,
    },
    '&.selected': {
      border: `1px solid  ${theme.palette.text.light.secondary}`,
    },
    img: {
      objectFit: 'contain',
      height: '100%',
    },
    'span.lazy-load-image-background': {
      width: '100%',
      height: 56,
      lineHeight: '0',
    },
    [theme.breakpoints.up(768)]: {
      width: `calc(${sliderImageProps.width}% - 16px)`,
    },
  },
  '.swiper-horizontal .swiper-pagination-progressbar': {
    width: '50vw',
    left: '25vw',
    height: '2px',
    top: '60%',
    bottom: '0',
    backgroundColor: theme.palette.background.light.tertiary,
    '&-fill': {
      backgroundColor: theme.palette.color.black,
    },
  },
}))

const ProductFullPageGalleryImagesSlider = styled(ProductImagesSlider, {
  name: 'ProductFullPageGallery',
  slot: 'ImagesSlider',
})(({ theme }) => ({
  marginBottom: theme.spacing(15),
  '.swiper-slide': {
    '&:not(.selected)': {
      opacity: '0 !important',
    },
  },
}))

const ProductImagesMainSlider = styled(ProductImagesSlider, {
  name: 'ProductImages',
  slot: 'MainSlider',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '0',

  [theme.breakpoints.up('md')]: {
    '.swiper': {
      cursor:
        // eslint-disable-next-line quotes
        "url(\"data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%238afjeh34ua)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20.436 19.73a6.5 6.5 0 1 0-.707.707l4.418 4.417.707-.707-4.418-4.418z' fill='%23fff'/%3E%3C/g%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20.436 19.73a6.5 6.5 0 1 0-.707.707l4.418 4.417.707-.707-4.418-4.418zM15.5 21a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zM12 15.002v1h3V19h1v-2.998h3v-1h-3V12h-1v3.002h-3z' fill='%2300303C'/%3E%3Cdefs%3E%3Cfilter id='8afjeh34ua' x='6' y='7' width='21.855' height='21.854' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='1'/%3E%3CfeGaussianBlur stdDeviation='1.5'/%3E%3CfeColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0'/%3E%3CfeBlend in2='BackgroundImageFix' result='effect1_dropShadow_1587_230429'/%3E%3CfeBlend in='SourceGraphic' in2='effect1_dropShadow_1587_230429' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E\") 32 32, pointer",
    },
    paddingRight: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    width: `calc(100% - ${theme.spacing(32)})`,
    flex: '1 0 auto',
    height: 'fit-content',
  },

  '.swiper-wrapper': {
    alignItems: 'center',
    display: 'flex',
  },

  '.swiper-slide': {
    backgroundColor: theme.palette.custom.white,
  },

  '.swiper-slide span': {
    padding: '5px 0',
  },

  '.swiper-slide img, .swiper-slide video': {
    maxHeight: 374,
  },

  video: {
    width: '100%',
  },
}))

const ProductZoomImagesSlider = styled(ProductImagesSlider, {
  name: 'ProductZoom',
  slot: 'ImagesSlider',
})(() => ({
  padding: '0',
  maxHeight: 'inherit',

  // ProductImageZoomMagnifier
  '& + div': {
    visibility: 'hidden',
  },

  // ProductImageZoomMagnifier
  '&:hover + div': {
    visibility: 'visible',
  },
}))

const StyledShippingTimesContainer = styled('div', {
  name: 'ShippingTimesContainer',
  slot: 'ShippingTimesContainer',
})(({ theme }) => ({
  padding: theme.spacing(4),
  color: theme.palette.text.dark.primary,
  backgroundColor: theme.palette.background.light.secondary,
  fontSize: 12,
}))

const StyledSectionContainer = styled('div', {
  shouldForwardProp: prop => prop === 'visible' || prop === 'children',
})<{
  visible?: boolean
}>(({ visible }) => ({
  display: visible ? 'flex' : 'none',
}))

const StyledLoaderContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
}))

const StyledLoaderWrapper = styled('div')(() => ({
  display: 'flex',
  height: '100%',
}))

const StyledSuggestionContainer = styled('div')(({ theme }) => ({
  gridColumn: '1 / -1',
  // minHeight: '351px',
  backgroundColor: theme.palette.background.light.secondary,
  [theme.breakpoints.up('md')]: {
    minHeight: '451px',
    //paddingTop: theme.spacing(7),
  },
}))

const StyledSelectLensesCTA = styled(Button)(({ theme }) => ({
  minWidth: 163,
  height: 40,
  flexGrow: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2),
  whiteSpace: 'nowrap',
}))

const StyledTrustBoxContainer = styled('div', {
  name: 'TrustPilot',
  slot: 'Container',
})(({ theme }) => ({
  margin: `${theme.spacing(10)} ${theme.spacing(20)}`,
  [theme.breakpoints.down(680)]: {
    height: '300px',
  },
  [theme.breakpoints.down(520)]: {
    margin: 0,
    marginTop: theme.spacing(10),
  },
}))

const StyledRelatedProduct = styled(Link, {
  name: 'PdpDetails',
  slot: 'RelatedProduct',
})(({ theme }) => ({
  display: 'flex',
  fontSize: 12,
  textDecoration: 'none',
  color: theme.palette.text.dark.primary,
  fontWeight: 600,
  alignItems: 'center',
  cursor: 'pointer',

  span: {
    textDecoration: 'underline',
  },
}))

const StyledImageWrapper = styled('div', {
  name: 'PdpDetails',
  slot: 'ImageWrapper',
})(() => ({
  height: 35,
  width: 66,
}))

const StyledCLAccessoriesContainer = styled('div', {
  name: 'PdpDetails',
  slot: 'CLAccessoriesContainer',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  color: theme.palette.text.dark.primary,
}))

export const RightColumnErrorContainer = styled('div', {
  name: 'PdpDetails',
  slot: 'ErrorContainer',
})(({ theme }) => ({
  color: theme.palette.color.error,
  fontSize: theme.typography.body1.fontSize,
}))

const StyledCLAccessoriesPrice = styled('div', {
  name: 'PdpDetails',
  slot: 'CLAccessoriesPrice',
})({
  display: 'flex',
  alignItems: 'flex-end',
})

const StyledCLAccessoriesLabel = styled('div', {
  name: 'PdpDetails',
  slot: 'CLAccessoriesLabel',
})({
  fontSize: 12,
  fontWeight: 'bold',
  display: 'flex',
  flexGrow: 2,
})

const StyledCLAccessoriesInitialLabel = styled('span', {
  name: 'PdpDetails',
  slot: 'CLAccessoriesInitialLabel',
})(({ theme }) => ({
  fontSize: 12,
  textDecoration: 'line-through',
  paddingRight: theme.spacing(1.5),
}))

const StyledCLAccessoriesSelect = styled('div', {
  name: 'PdpDetails',
  slot: 'CLAccessoriesSelect',
})(({ theme }) => ({
  fontSize: 12,
  display: 'flex',
  flexGrow: 1,
  '.MuiSelect-icon': {
    top: `${theme.spacing(1.8)} !important`,
  },
  '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.background.light.quaternary,
  },
  '.MuiSelect-iconOpen': {
    transform: 'rotate(0deg)',
  },
  '.is-select': {
    minHeight: '33px',
  },
}))

const StyledFramesRightColumnContainer = styled('span', {
  name: 'PdpFrames',
  slot: 'RightColumnContainer',
})(({ theme }) => ({
  all: 'inherit',
  paddingLeft: theme.spacing(5),
}))

const StyledCLAccessoriesWrapper = styled('div', {
  name: 'PdpDetails',
  slot: 'CLAccessoriesWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  fontSize: 14,
  gap: theme.spacing(4),
  color: theme.palette.text.dark.primary,
}))

const StyledProductBoxesQuantity = styled('div', {
  name: 'PdpDetails',
  slot: 'BoxesQuantity',
})(() => ({
  display: 'flex',
  justifyContent: 'start',
}))

const FramesPdpRightColumnInfoContainer = styled('div', {
  name: 'PdpDetails',
  slot: 'FrameInfoContainer',
})(({ theme }) => ({
  padding: `${theme.spacing(3)} 0`,
  fontSize: 12,
}))

const FramesPdpRightColumnInfo = styled('div', {
  name: 'PdpDetails',
  slot: 'FrameInfo',
})(({ theme }) => ({
  marginBottom: theme.spacing(2),
  'span:first-of-type': {
    fontWeight: theme.typography.fontWeightBold,
    marginRight: theme.spacing(1),
  },
}))

const VirtualMirrorPill = styled(Pill, {
  name: 'VirtualMirror',
  slot: 'Pill',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  backgroundColor: '#f6f6f6',
  border: '1px solid #d8d8d8',
}))

export {
  PdpContainer,
  TrustPilotMiniWidgetContainer,
  PdpLeftColumn,
  PdpRightColumn,
  SoldOutImagePlaceholderContainer,
  SoldOutTitle,
  SoldOutDescription,
  StyledTitleContent,
  StyledProductName,
  StyledSectionContainer,
  StyledLeftColumnContainer,
  StyledRightColumnContainer,
  VirtualMirrorWrapper,
  StyledButton,
  StyledSeparator,
  ProductImagesMainSlider,
  ProductFullPageGalleryThumbsSlider,
  ProductFullPageGalleryImagesSlider,
  ProductZoomImagesSlider,
  SliderWrapper,
  StyledLoaderContainer,
  StyledLoaderWrapper,
  StyledSuggestionContainer,
  StyledSelectLensesCTA,
  StyledTrustBoxContainer,
  StyledRelatedProduct,
  StyledImageWrapper,
  StyledCLAccessoriesContainer,
  StyledCLAccessoriesLabel,
  StyledCLAccessoriesSelect,
  StyledCLAccessoriesPrice,
  StyledCLAccessoriesInitialLabel,
  StyledFramesRightColumnContainer,
  StyledCLAccessoriesWrapper,
  StyledShippingTimesContainer,
  StyledProductBoxesQuantity,
  FramesPdpRightColumnInfo,
  FramesPdpRightColumnInfoContainer,
  VirtualMirrorPill,
}
