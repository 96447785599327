import React from 'react'
import clsx from 'clsx'
import { ITeaserCallToAction } from '@typesApp/cmsPlacement/LXTeaser'
import { useStoreIdentity } from '../../../../../foundation/hooks/useStoreIdentity'
import { CmsIcon } from '@components/Cms/CmsComponents-CSS/CmsIcon'
import HTMLReactParser from 'html-react-parser'
import { useExternalsActions } from '@hooks/useExternalsAction'
import { ICMAction } from '@typesApp/cmsPlacement/CMAction'
import { Link } from '@components/common/Link/Link'

import styles from '../styles/Omnichannel.module.scss'

export interface IProps {
  actionSettings: ITeaserCallToAction
  dataElementId: string
  icon: string
  title: string
  text?: string
}

export const OmniChannelLink: React.FC<IProps> = props => {
  const { actionSettings, dataElementId, icon, title, text } = props
  const { basePath } = useStoreIdentity()
  const actions = useExternalsActions()

  const ctaEnabled = actionSettings?.callToActionEnabled || false
  const formattedUrl = actionSettings?.target?.formattedUrl || ''
  const link = actionSettings?.target?.type === 'Action' ? '#' : `${basePath}${formattedUrl ?? ''}`

  const handleAction =
    actionSettings?.target?.type === 'Action'
      ? event => {
          event.preventDefault()
          actions?.[(actionSettings?.target as ICMAction)?.idAction]((actionSettings?.target as ICMAction)?.title)
        }
      : undefined

  return (
    <div className={clsx(styles.omnichannelInfoWrapper)}>
      {ctaEnabled && (
        <Link
          href={link}
          onClick={handleAction}
          data-element-id={dataElementId}
          className={clsx(styles.omnichannelCta)}
        />
      )}
      {icon && <CmsIcon teaserIcon={icon} size={48} />}
      <div className={clsx(styles.omnichannelContent)}>
        {title && <div className={clsx(styles.omnichannelHeading)}>{title}</div>}
        {text && <div className={clsx(styles.omnichannelParsedContent)}>{HTMLReactParser(text)}</div>}
      </div>
    </div>
  )
}
