import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { ClusterProduct, IProduct } from '@typesApp/product'
import {
  getCurrentCluster,
  getCurrentProductItemFromProductData,
  getSuggestedProducts,
  isFormattedProductCLAccessories,
  isFormattedProductContactLenses,
} from '@utils/product'
import { getIsRoxable } from '@utils/productAttributes'
import { seoUrlConcat } from '@utils/url'
import { useEffect, useMemo, useState } from 'react'
import Log from '../../services/Log'
/**
 * useProductData custom react hook
 * @param pdpData IProduct
 * @returns {Record}
 */
const useProductData = (
  pdpData: IProduct | null | undefined,
  productPartNumber: string
): {
  currentProductItem: IProduct | null | undefined
  pathName: string | null
  canonicalCategoryIdentifier: string | null | undefined
  partNumber: string | null | undefined
  suggestedProducts: IProduct['merchandisingAssociations']
  isRoxable: boolean
  currentCluster: ClusterProduct | null
} => {
  const { basePath } = useStoreIdentity()
  const [currentProductItem, setCurrentProductItem] = useState<IProduct | null | undefined>(
    getCurrentProductItemFromProductData(pdpData?.partNumber ?? '', pdpData)
  )
  const [pathName, setPathName] = useState<string | null>(null)
  const [canonicalCategoryIdentifier, setCanonicalCategoryIdentifier] = useState<string | null | undefined>(null)
  const [partNumber, setPartNumber] = useState<string | null | undefined>(pdpData?.partNumber)
  const [suggestedProducts, setSuggestedProducts] = useState<IProduct['merchandisingAssociations']>([])
  const [currentCluster, setCurrentCluster] = useState<IProduct | null>(null)

  useEffect(() => {
    setCurrentProductItem(() => {
      return pdpData?.partNumber && productPartNumber
        ? getCurrentProductItemFromProductData(productPartNumber, pdpData)
        : null
    })
    setPartNumber(() => {
      return productPartNumber
    })
  }, [pdpData, productPartNumber])

  useEffect(() => {
    const newPathname = currentProductItem?.seo?.href ? seoUrlConcat(basePath, currentProductItem?.seo?.href) : null
    setPathName(() => {
      return newPathname
    })
    const canonicalCategoryIdentifier = currentProductItem
      ? currentProductItem.productAttributes['LX_CANONICAL_CATEGORY']
      : null
    setCanonicalCategoryIdentifier(canonicalCategoryIdentifier)
    if (
      isFormattedProductContactLenses(currentProductItem?.productAttributes['PRODUCT_TYPE'] || '') ||
      isFormattedProductCLAccessories(currentProductItem?.productAttributes['PRODUCT_TYPE'] || '')
    ) {
      setSuggestedProducts(() => {
        if (currentProductItem?.merchandisingAssociations === null) {
          return []
        } else {
          const merchandisingItems = getSuggestedProducts(currentProductItem?.merchandisingAssociations)
          return merchandisingItems
        }
      })
    }
  }, [basePath, currentProductItem])

  useEffect(() => {
    setCurrentCluster(() => {
      return getCurrentCluster(partNumber || '', pdpData?.cluster)?.find(cluster => !!cluster) || null
    })
  }, [pdpData, partNumber])

  useEffect(() => {
    try {
      if (!currentCluster) {
        return
      }
      setSuggestedProducts(() => {
        if (currentCluster?.merchandisingAssociations === null) {
          return []
        } else {
          const merchandisingItems = getSuggestedProducts(currentCluster?.merchandisingAssociations)
          return merchandisingItems
        }
      })
    } catch (e) {
      if (e instanceof Error) Log.error('Could not retry product details data: ' + e.message)
    }
  }, [currentCluster])

  const isRoxable = useMemo(
    () => (currentProductItem && getIsRoxable(currentProductItem)) || false,
    [currentProductItem]
  )

  return {
    currentProductItem,
    pathName,
    canonicalCategoryIdentifier,
    partNumber,
    suggestedProducts,
    isRoxable,
    currentCluster,
  }
}

export default useProductData
