import { MultipleQueriesQuery, SearchResponse } from '@algolia/client-search'
import config from '@configs/index'
import { IPlpProductArgs } from '@features/plp/query'
import { getAttributesFromUrl } from '@features/plp/utils'
import { algoliaClient, initIndexName } from '@foundation/algolia/algoliaConfig'
import { determineAlgoliaPrice } from '@foundation/algolia/algoliaPrice'
import { IAlgoliaHit } from '@typesApp/product'
import { fromUrlParamsToObject } from '@utils/url'
import { SearchClient } from 'algoliasearch'
import type { CurrentRefinementsConnectorParamsItem } from 'instantsearch.js/es/connectors/current-refinements/connectCurrentRefinements'
import { ParsedUrlQuery } from 'querystring'
import { IPlpState, IPlpStateBase, Refinement } from './PlpReducer'
import { SEO_PAGE_TYPES } from '@foundation/hooks/useSeoComponent'

function transformStoreInfoLocale(locale: string | undefined) {
  if (!locale) return null
  const splitted = locale.split('_')
  splitted[1] = splitted[1].toUpperCase()
  return splitted.join('_')
}

export const createPlpInitialState = ({
  pageType,
  seoData,
  storeInfo,
  serverUrl,
  cookies,
  searchParams,
  hostname,
}: IPlpStateBase): IPlpState => {
  const locale = seoData?.language ?? transformStoreInfoLocale(storeInfo?.locale) ?? config.defaultCountry
  const categoryIdentifier = seoData?.tokenExternalValue as string
  const groupedIndexName = initIndexName({ locale, isGrouped: true })
  const ungroupedIndexName = initIndexName({ locale, isGrouped: false })
  const sortOrderOptions = Object.keys(storeInfo?.xStoreCfg.sortorderOptions ?? {}).map(sortOptionKey => {
    const sortOption = storeInfo?.xStoreCfg.sortorderOptions[sortOptionKey]
    const indexName = initIndexName({
      locale,
      sortOption: Number(sortOption?.value),
      customerSegment: cookies?.customerSegment || 'Guest',
    })
    return {
      id: Number(sortOption?.value),
      label: sortOptionKey,
      value: indexName,
      profileName: sortOption?.profileName as string,
    }
  })

  const allowedPlpPages = [SEO_PAGE_TYPES.PRODUCT_LIST_PAGE, SEO_PAGE_TYPES.CATEGORY_PAGE]

  return {
    firstLoad: true,
    seoData,
    storeInfo,
    locale,
    categoryId: (seoData?.tokenValue as string) ?? null,
    categoryIdentifier: (categoryIdentifier as string) ?? null,
    groupedIndexName,
    ungroupedIndexName,
    isAlgoliaBasedPlp: true,
    algoliafilterCategory: allowedPlpPages.includes(pageType ?? '')
      ? `categories:"${seoData?.tokenExternalValue}"`
      : '',
    openDrawerFilters: false,
    sortOrderOptions,
    showOffersFilters: false,
    // storeInfo?.xStoreCfg?.plpRadioButtonFacets.some(categoryItem => {
    //   return categoryItem.toLowerCase().endsWith(categoryIdentifier?.toLowerCase())
    // }) || false,
    shouldLoadUngrouped: false,
    appliedFacets: [],
    _internalAppliedFacets: [],
    serverUrl,
    productsMobileListingLayout: 'compact',
    lastSelectedProduct: '',
    ungroupedHits: [],
    analyticsData: {
      Page_Brand: storeInfo?.xStoreCfg?.analyticsData?.Page_Brand || '',
      Page_Design: '',
      Page_Country: locale.split('_')[1].toUpperCase() || '',
      Page_Language: locale.split('_')[0].toUpperCase() || '',
      Page_DeviceType: 'all',
      Page_Platform: storeInfo?.xStoreCfg?.analyticsData?.Page_Platform || 'WCS',
      Page_Server: hostname || storeInfo?.xStoreCfg?.analyticsData?.Page_Server || '',
      Page_Section2: '',
      Order_Currency: storeInfo ? storeInfo.defaultCurrencyID : '',
      Store_Id: storeInfo ? storeInfo.storeID : '',
    },
    cookies: cookies ?? null,
    isPlpSeoEnabled: {
      top: storeInfo?.xStoreCfg?.plpSeoBlockEnabled || false,
      bottom: storeInfo?.xStoreCfg?.plpSeoBlockEnabled || false,
    },
    isWishlistEnabled: storeInfo?.xStoreCfg.wishlistEnabled || false,
    wishlistItems: [],
    searchParams,
    pageType,
    hostname,
  }
}

export interface IGetFacets {
  facets: Record<string, Record<string, number>>
  suggestedFacets: { key: string; value: string[] }[]
}

export const getFacets = async (
  searchClient: SearchClient,
  indexName: string,
  renderingContentFacets: string[] | undefined,
  customerSegment: string,
  showOffersFilters: boolean,
  categoryFilter: string,
  query: string
): Promise<IGetFacets> => {
  if (!renderingContentFacets)
    return Promise.resolve({
      facets: {},
      suggestedFacets: [],
    })

  const queryForFacetsToDisplay = [
    {
      indexName,
      params: {
        query: query || '',
        attributesToRetrieve: [],
        facets: renderingContentFacets,
        ...(!query ? { filters: categoryFilter } : {}),
      },
    },
    {
      indexName,
      params: {
        query: '',
        attributesToRetrieve: [],
        facets: ['*'],
        ...(!query ? { filters: categoryFilter } : {}),
        ruleContexts: ['suggested_filters'],
      },
    },
  ]

  return searchClient.multipleQueries<IAlgoliaHit>(queryForFacetsToDisplay).then(({ results }) => {
    const { facets, renderingContent } = results[0]
    if (!facets) return { facets: {}, suggestedFacets: [] }

    const facetValues = Object.entries(facets).map(([key, value]) => ({
      key,
      value,
    }))
    const filteredFacets = facetValues.filter(facet => !facet.key.startsWith('sortDiscount_'))
    const discountItem = facetValues.find(facet => facet.key === `sortDiscount_${customerSegment}`)
    if (!showOffersFilters && discountItem) {
      filteredFacets.push(discountItem)
    }

    const suggestedFacets = results[1].renderingContent?.facetOrdering?.values

    let filteredSuggestedFacets = []

    if (
      renderingContent &&
      renderingContent.facetOrdering &&
      renderingContent.facetOrdering.facets &&
      renderingContent.facetOrdering.facets.order &&
      renderingContent.facetOrdering.facets.order.length !==
        results[1].renderingContent?.facetOrdering?.facets?.order?.length
    ) {
      let parsedSuggestedFacets
      if (suggestedFacets) {
        parsedSuggestedFacets = Object.keys(suggestedFacets).map(key => {
          return {
            key: key,
            value: suggestedFacets[key].order,
          }
        })
      }

      filteredSuggestedFacets = parsedSuggestedFacets
        .map(facet => {
          const foundFacet = filteredFacets.find(ff => ff.key === facet.key)
          if (foundFacet) {
            const allFacetValues = Object.keys(foundFacet.value)
            const activeFilters = facet.value
              .map(value => {
                const foundValue = allFacetValues.find(v => v === value)
                return foundValue ? foundValue : null
              })
              .filter(val => val)

            return {
              key: facet.key,
              value: activeFilters,
            }
          }
          return null
        })
        .filter(f => f)
    }

    return {
      facets: filteredFacets.reduce<Record<string, Record<string, number>>>((acc, facet) => {
        if (facet.key !== 'attributes.LX_SEARCH_PAGE_PRODUCT_TYPE') acc[facet.key] = facets[facet.key]
        return acc
      }, {}),
      suggestedFacets: filteredSuggestedFacets,
    }
  })
}

export const getAlgoliaUiStateFromUrl = (query: string) => {
  const queryParams: IPlpProductArgs = fromUrlParamsToObject(query, true)
  const parameters: IPlpProductArgs = {
    range: undefined,
  }

  if (queryParams.facet) {
    parameters.facet = queryParams.facet
  }

  if (queryParams.page) {
    parameters.page = queryParams.page
  }

  if (queryParams.sortBy) {
    parameters.sortBy = queryParams.sortBy
  }

  if (parameters.query) {
    parameters.query = queryParams.query
  }

  if (queryParams.priceFrom) {
    parameters.priceFrom = queryParams.priceFrom
  }

  if (queryParams.priceTo) {
    parameters.priceTo = queryParams.priceTo
  }

  if (queryParams.discount) {
    parameters.discount = queryParams.discount
  }

  const refinementList = getAttributesFromUrl(queryParams, [])

  return { ...parameters, refinementList }
}

export const flatRefinementList = (currentRefinements: CurrentRefinementsConnectorParamsItem[]): Refinement[] => {
  return currentRefinements
    .map(item =>
      item.refinements.map(refinement => ({
        ...refinement,
        refine: item.refine,
      }))
    )
    .flat()
}

export const createUngroupedQueriesByX_groupkey = ({
  groupedHits,
  indexName,
  algoliafilterCategory,
  query,
  customerSegments,
  searchParams,
}: {
  groupedHits: IAlgoliaHit[]
  indexName: string
  algoliafilterCategory: string | undefined
  query: string
  customerSegments: string[]
  searchParams?: ParsedUrlQuery
}): MultipleQueriesQuery[] => {
  const facetsFromUrl = facetFiltersStringFromUrl(searchParams, customerSegments)
  return groupedHits.map(({ x_groupkey }: IAlgoliaHit) => ({
    indexName,
    query,
    params: {
      filters: `${algoliafilterCategory ? `${algoliafilterCategory} AND ` : ''}x_groupkey:"${x_groupkey}"`,
      ...(facetsFromUrl.length > 0 ? { facetFilters: facetsFromUrl } : {}),
      ...(typeof window === 'undefined' ? { hitsPerPage: 1 } : {}),
      attributesToHighlight: [],
    },
  }))
}

export const fetchHitsByMultipleQueries = async (
  queries: MultipleQueriesQuery[]
): Promise<SearchResponse<IAlgoliaHit>[]> => {
  return await algoliaClient.multipleQueries<IAlgoliaHit>(queries).then(({ results }) => results)
}

export const ungroupedHitsMapper = (
  results: SearchResponse<IAlgoliaHit>[],
  customerSegments: string[]
): IAlgoliaHit[] => {
  return results.map(result => {
    const hitsWithPrices = result.hits.map(hit => ({
      ...hit,
      price: determineAlgoliaPrice(hit.prices, customerSegments) ?? null,
    }))

    const firstProduct = result.hits[0]
    return {
      ...firstProduct,
      clusters: hitsWithPrices,
    }
  })
}

const facetFiltersStringFromUrl = (parmeters, customerSegments) => {
  const selectedFacetsInternal = getAttributesFromUrl(parmeters, customerSegments)

  return Object.keys(selectedFacetsInternal).map(attribute => {
    if (Array.isArray(selectedFacetsInternal[attribute])) {
      return selectedFacetsInternal[attribute].map(value => `${attribute}:${value}`)
    } else {
      return `${attribute}:${selectedFacetsInternal[attribute]}`
    }
  })
}

export const sortSizes = (sizes: string[]): string[] => {
  const sizeOrder = ['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl']
  return sizes.sort((a, b) => sizeOrder.indexOf(a) - sizeOrder.indexOf(b))
}
