import React, { useRef, useState } from 'react'
import { useTranslation } from 'next-i18next'

import {
  SearchInput,
  SearchInputIconWrapper,
  SearchInputWrapper,
  StyledAccordion,
  StyledAccordionSummary,
  StyledFilterNotes,
  StyledFiltersContainer,
} from '../PlpFilter.style'
import { useRefinementList } from 'react-instantsearch-hooks-web'
import { useSite } from '@foundation/hooks/useSite'
import { SizeAdvisorFilter } from './SizeAdvisorFilter'
import FacetFilterPill from './FacetFilterPill'
import type { SearchResults } from 'algoliasearch-helper'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

const FACET_PRODUCT_TYPE = 'SKU_TYPE'
const FRAME_SIZE_DISPLAY_RANGE = 'FRAME_SIZE_DISPLAY_RANGE'
const FRAME_TYPE = 'FRAME_TYPE'
const GEOFIT = 'GEOFIT'
interface FilterListAlgoliaProps {
  facet: string
  onFacetChange: (selection: string, label: string, parent: string, setFacet: (string) => void) => void
  facetValues?: string[]
  discountItems: SearchResults.HierarchicalFacet[]
  sizeAdvisorSwitcherProps: {
    toggleHingesFacet?: () => void
    enabled: boolean
  }
  hasSuggested: boolean
}

const FilterListAlgolia = ({
  facet,
  onFacetChange,
  facetValues,
  discountItems,
  sizeAdvisorSwitcherProps,
  hasSuggested,
}: FilterListAlgoliaProps) => {
  const { t } = useTranslation()
  const { mySite } = useSite()
  const frameGeniusStoreconfs = mySite.xStoreCfg.frameGenius
  const isSizeAdvisorEnabled = frameGeniusStoreconfs.isSizeAdvisorEnabled

  const facetName = facet?.split('.')[1] ?? 'DISCOUNT'

  const isExpanded = !hasSuggested ? (facetName === 'CL_BRAND' ? true : facetName === FACET_PRODUCT_TYPE) : false

  const filterRef = useRef<HTMLDivElement>(null)

  const { items, searchForItems } = useRefinementList({
    attribute: facet,
    operator: 'or',
    sortBy: ['count'],
    limit: facetValues?.length,
  })

  const [searchTerm, setSearchTerm] = useState<string>('')

  const onSearchInputChange = e => {
    const { value } = e.target
    setSearchTerm(value)
    searchForItems(value)
  }

  const appliedFiltersCount =
    isSizeAdvisorEnabled && sizeAdvisorSwitcherProps.enabled && facetName === FRAME_SIZE_DISPLAY_RANGE
      ? 1
      : items.filter(item => item.isRefined).length

  const renderFacetValues = (facetValues: string[], facetName: string, facet: string, searchTerm: string) => {
    return (
      <FacetFilterPill
        facetValues={facetValues}
        facetName={facetName}
        facet={facet}
        discountItems={discountItems}
        onFacetChange={onFacetChange}
        searchTerm={searchTerm}
      />
    )
  }

  return (
    <StyledAccordion defaultExpanded={isExpanded}>
      <StyledAccordionSummary
        expandIcon={<SVGIcon library="arrow" name="arrow-down" />}
        data-testid={`filtersFacet${facetName}`}
      >
        {appliedFiltersCount && appliedFiltersCount > 0
          ? `${t(`ProductFilter.Labels.${facetName}`)} (${appliedFiltersCount.toString()} ${t(
              'ProductFilter.Labels.applied'
            )})`
          : t(`ProductFilter.Labels.${facetName}`)}
      </StyledAccordionSummary>
      <StyledFilterNotes ref={filterRef}>
        {facetName === FRAME_SIZE_DISPLAY_RANGE || facetName === GEOFIT || facetName === FRAME_TYPE
          ? t(`ProductFilter.Notes.${facetName}`)
          : ''}
      </StyledFilterNotes>
      {facetName === 'BRAND' && (
        <SearchInputWrapper>
          <SearchInput
            type={'text'}
            placeholder={t('ProductFilter.Actions.Search')}
            value={searchTerm}
            onChange={onSearchInputChange}
          />
          <SearchInputIconWrapper>
            <SVGIcon library="global" name="search" />
          </SearchInputIconWrapper>
        </SearchInputWrapper>
      )}
      <div>
        <StyledFiltersContainer>
          {facetName === FRAME_SIZE_DISPLAY_RANGE ? (
            <>
              {!sizeAdvisorSwitcherProps.enabled &&
                facetValues &&
                facetValues.length > 0 &&
                renderFacetValues(facetValues, facetName, facet, searchTerm)}
              {isSizeAdvisorEnabled && <SizeAdvisorFilter sizeAdvisorSwitcherProps={sizeAdvisorSwitcherProps} />}
            </>
          ) : (
            facetValues && facetValues.length > 0 && renderFacetValues(facetValues, facetName, facet, searchTerm)
          )}
        </StyledFiltersContainer>
      </div>
    </StyledAccordion>
  )
}

export default FilterListAlgolia
