import { useForm } from 'react-hook-form'
import {
  ContactLensFormFields,
  ContactLensValidationSchema,
} from '@views/ProductDetails/components/ContactLensSelection/contactLens.schema'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useRxTaxExemption } from '@foundation/hooks/useRxTaxExemption'
import { useLazyGetCartQuery, useLazyGetPrescriptionDetailsQuery } from '@features/order/query'
import { useSite } from '@foundation/hooks/useSite'
import { format } from 'date-fns'
import { toIssueFormat } from '@utils/dateUtils'

type UsePDPFormParams = {
  isEditingContactLens: boolean
  isNonPrescriptionColoredLens: boolean
  isColorContacts?: boolean
}

export function usePDPForm(params: UsePDPFormParams) {
  const { isEditingContactLens, isNonPrescriptionColoredLens, isColorContacts } = params

  const { mySite } = useSite()
  const [getCart] = useLazyGetCartQuery()
  const rxTaxExemption = useRxTaxExemption()
  const [getPrescriptionDetailsQuery] = useLazyGetPrescriptionDetailsQuery()

  const defaultValues = {
    doctor: '',
    issue: null,
    isAcknowledged: false,
  }

  const fetchDefaultValues = async () => {
    if (!isEditingContactLens || !rxTaxExemption.isEnabled) {
      return defaultValues
    }

    const cartResponse = await getCart({ storeId: mySite.storeId }).unwrap()
    if (!cartResponse) {
      return defaultValues
    }

    const prescriptionDetails = await getPrescriptionDetailsQuery({
      orderId: cartResponse.cart.orderId,
      orderItemId: cartResponse.cart.orderItem?.[0]?.orderItemId,
    })

    if (!prescriptionDetails.data) {
      return defaultValues
    }

    const { doctor, issue } = prescriptionDetails.data.results

    return {
      doctor,
      issue: toIssueFormat(issue),
      isAcknowledged: false,
    }
  }

  const context = {
    isFieldRequired: isNonPrescriptionColoredLens,
    issueIsRequired: isNonPrescriptionColoredLens,
    doctorIsRequired: isNonPrescriptionColoredLens || rxTaxExemption.requireBasicInfo,
    requiresAcknowledgement: rxTaxExemption.requiresAcknowledgement,
  }
  const formMethods = useForm<ContactLensFormFields>({
    context,
    defaultValues: fetchDefaultValues,
    mode: 'onSubmit',
    resolver: yupResolver(ContactLensValidationSchema, { context }),
  })

  const issueDate = formMethods.watch('issue')
  const doctorName = formMethods.watch('doctor')
  const isAcknowledged = formMethods.watch('isAcknowledged')

  const prescriptionDetailsIsValid = rxTaxExemption.isEnabled && formMethods.formState.isValid
  const [showPrescriptionsDetailsError, setShowPrescriptionsDetailsError] = useState(
    (rxTaxExemption.isEnabled && !prescriptionDetailsIsValid && formMethods.formState.isDirty) ||
      Object.keys(formMethods.formState.errors).length > 0
  )

  useEffect(() => {
    if (context.isFieldRequired && isColorContacts) {
      formMethods.clearErrors()
      formMethods.trigger()
    }
  }, [context.isFieldRequired, formMethods, isColorContacts])

  return {
    formMethods,
    issueDate,
    doctorName,
    isAcknowledged,
    showPrescriptionsDetailsError,
    setShowPrescriptionsDetailsError,
  }
}
