import styled from '@mui/material/styles/styled'
import { TOverlayTextAlign, TeaserBackGround, TeaserOverlayStyle } from '../../../types/teaser'
import { teaserOverlayStyleToColor, teaserBackGroundColorToBg } from '../../../utils/placements'
import { IViewType } from '@typesApp/cmsPlacement/ViewType'

export const WrapperPlpTiles = styled('div', {
  name: 'PlpTiles',
  slot: 'Wrapper',
  shouldForwardProp: propName => propName !== 'bgColor',
})<{ bgcolor: React.CSSProperties['backgroundColor'] | TeaserBackGround }>(({ theme, bgcolor }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  color: theme.palette.text.dark.primary,
  ...teaserBackGroundColorToBg(bgcolor, theme),
}))

export const ImagePlpTiles = styled('div', {
  name: 'PlpTiles',
  slot: 'Image',
  shouldForwardProp: propName => propName !== 'showGradient',
})<{ showGradient?: boolean }>(({ showGradient = false }) => ({
  display: 'flex',
  position: 'relative',

  background: showGradient ? 'linear-gradient(90deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%)' : undefined,

  '.image-container, a': {
    width: '100%',
  },
}))

export const PreTitlePlpTiles = styled('div', {
  name: 'PlpTiles',
  slot: 'PreTitle',
})(() => ({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
}))

export const TitlePlpTiles = styled('div', {
  name: 'PlpTiles',
  slot: 'Title',
  shouldForwardProp: prop => prop !== 'overlayTxtAlign' && prop !== 'teaserOverlayStyle' && prop !== 'viewType',
})<{
  overlayTxtAlign?: TOverlayTextAlign
  teaserOverlayStyle?: TeaserOverlayStyle
  viewType?: IViewType
}>(({ overlayTxtAlign: overlaytxtalign, teaserOverlayStyle: teaseroverlaystyle, viewType, theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  fontWeight: '700',
  textAlign: viewType === 'plp-one-tile' ? overlaytxtalign : 'center',
  ...teaserOverlayStyleToColor(theme, teaseroverlaystyle),

  [theme.breakpoints.up('sm')]: {
    textAlign: overlaytxtalign,
  },
}))

export const TextPlpTiles = styled('div', {
  name: 'PlpTiles',
  slot: 'Text',
  shouldForwardProp: prop => prop !== 'overlayTxtAlign' && prop !== 'teaserOverlayStyle' && prop !== 'viewType',
})<{
  overlayTxtAlign?: TOverlayTextAlign
  teaserOverlayStyle?: TeaserOverlayStyle
  viewType?: IViewType
}>(({ overlayTxtAlign: overlaytxtalign, teaserOverlayStyle: teaseroverlaystyle, viewType, theme }) => ({
  ...teaserOverlayStyleToColor(theme, teaseroverlaystyle),
  textAlign: viewType === 'plp-one-tile' ? overlaytxtalign : 'center',
  fontSize: theme.typography.body2.fontSize,

  [theme.breakpoints.up('sm')]: {
    textAlign: overlaytxtalign,
  },
}))

export const ContentPlpTiles = styled('div', {
  name: 'PlpTiles',
  slot: 'Content',
  shouldForwardProp: prop => prop !== 'showInline',
})<{
  showInline?: boolean
}>(({ showInline = true, theme }) => ({
  padding: '10px 0 0',
  display: showInline ? 'flex' : 'block',
  overflow: 'hidden',
  a: {
    marginRight: 0,
    ...(!showInline && {
      marginLeft: 0,
      width: '100%',
    }),
  },

  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
}))

export const CtaPlpTiles = styled('div', {
  name: 'PlpTiles',
  slot: 'Cta',
})(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4),
  flexDirection: 'column',
  flex: '0 0 auto',

  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    alignItems: 'center',
    a: {
      width: '100%',
    },
  },

  [theme.breakpoints.between(601, 'md')]: {
    justifyContent: 'center',
  },

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginLeft: 'auto',
  },
}))

export const ContentTextPlpTiles = styled('div', {
  name: 'PlpTiles',
  slot: 'ContentText',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  flex: '1 1 auto',
}))

export const OverlapTextModuleContainer = styled('div', {
  name: 'PlpTiles',
  slot: 'OverlapTextModuleContainer',
})(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  width: '50%',
  transform: 'translateY(-50%)',
  pointerEvents: 'none',

  [theme.breakpoints.up('md')]: {
    margin: '0 32px 0 32px',
  },

  [theme.breakpoints.up('lg')]: {
    margin: '0 64px 0 64px',
  },
}))

export const BelowImageTextModuleContainer = styled('div', {
  name: 'PlpTiles',
  slot: 'BelowImageTextModuleContainer',
})(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(4),

  [theme.breakpoints.up('md')]: {
    margin: '0 32px 0 32px',
  },

  [theme.breakpoints.up('lg')]: {
    margin: '0 64px 0 64px',
  },
}))

export const ShoppableContainer = styled('div', {
  name: 'PlpTiles',
  slot: 'Shoppable',
})(() => ({
  width: '100%',
}))
