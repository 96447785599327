import {
  StyledSuggestedProductSubWrapper,
  StyledSuggestedProductTileFooter,
  StyledSuggestedProductTileWrapper,
} from '@components/SuggestedProductTile/components/SuggestedProductTile.style'
import styled from '@mui/material/styles/styled'

export const StyledSuggestedProductTileWrapperAlgolia = styled(StyledSuggestedProductTileWrapper, {
  name: 'PdpDetails',
  slot: 'StyledSuggestedProductTileWrapperAlgolia',
})(({ theme }) => ({
  backgroundColor: theme.palette.custom.white,
  border: `1px solid ${theme.palette.custom.light.grey}`,
  borderRadius: theme.spacing(2),
  boxShadow: '0 6px 12px 0px rgba(96, 97, 112, 0.16)',
  gap: theme.spacing(6),
  maxWidth: '304px',
  padding: theme.spacing(4, 0, 2),
  width: 'calc(100% - 32px)',

  [theme.breakpoints.down('sm')]: {
    height: '100%',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 0, 2),
  },
}))

export const StyledSuggestedProductSubWrapperAlgolia = styled(StyledSuggestedProductSubWrapper, {
  name: 'PdpDetails',
  slot: 'StyledSuggestedProductSubWrapperAlgolia',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  '&::before': {
    paddingTop: 0,
  },
}))

export const StyledSuggestedProductTileFooterAlgolia = styled(StyledSuggestedProductTileFooter)(({ theme }) => ({
  padding: theme.spacing(0, 4),

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0),
  },
}))
