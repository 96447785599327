import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OrderItem } from '../types/order'
import { getFormattedLensData } from '../components/PrescriptionLenses/RxUtils'
import { useContactLensCartItemData } from './useContactLensCartItemData/useContactLensCartItemData'
import { setAddToCartBusy } from '../features/cartui/cartuiSlice'
import {
  prescriptionLensFormOpenSelector,
  clearPrescriptionLens,
  setPrescriptionLensFormOpen,
} from '../features/rox/roxSlice'
import { setCurrentProductBundle } from '../features/product/slice'
import { useEditCartItemParams } from './useEditCartItemParams'

export interface EditCartItemProps {
  orderItem: OrderItem | undefined
}

export interface UseEditCartItemReturn {
  isEditLens: boolean
  isEditContactLens: boolean
}

export const useEditCartItem = (props: EditCartItemProps): UseEditCartItemReturn => {
  const { orderItem } = props

  const dispatch = useDispatch()
  const { isEditLens, isEditContactLens } = useEditCartItemParams()
  const isPrescriptionLensesFormOpen = useSelector(prescriptionLensFormOpenSelector)
  const { orderItemClData } = useContactLensCartItemData({ orderItem: isEditContactLens ? orderItem : undefined })

  useEffect(() => {
    dispatch(clearPrescriptionLens())
    dispatch(setAddToCartBusy(isEditLens || isEditContactLens))
  }, [])

  useEffect(() => {
    if (!orderItem || isPrescriptionLensesFormOpen) return
    if (isEditLens) {
      dispatch(setPrescriptionLensFormOpen(true))
    }
  }, [orderItem])

  /**
   * Prepare the contact lens RX data
   */
  useEffect(() => {
    if (isEditContactLens && orderItem) {
      const cldata = getFormattedLensData(orderItemClData)
      if (cldata) {
        dispatch(setCurrentProductBundle({ contactLensData: cldata }))
      }
    }
  }, [orderItemClData])

  return {
    isEditLens,
    isEditContactLens,
  }
}
