import { ButtonAsLink } from '@components/UI/Button'
import { Pill } from '@components/UI/Pill'
import { IconButton } from '@components/UI/IconButton'
import { Z_INDEX_LV1, Z_INDEX_LV2, Z_INDEX_LV3, Z_INDEX_LV4 } from '../../../../constants/ui'

import Fade from '@mui/material/Fade'
import Typography from '@mui/material/Typography'
import styled from '@mui/material/styles/styled'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import Checkbox from '@mui/material/Checkbox'

export const StyledPLPHeaderWrapper = styled('div', {
  name: 'PlpHeader',
  slot: 'Wrapper',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  zIndex: Z_INDEX_LV2,
}))

export const StyledPlpHeaderWrapperMobile = styled('div', {
  name: 'PlpHeaderMobile',
  slot: 'Wrapper',
})(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}))

export const StyledPlpHeaderWrapperDesktop = styled('div', {
  name: 'PlpHeaderDesktop',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up('md')]: {
    display: 'block',
    paddingTop: theme.spacing(2),
  },
}))

export const StyledPLPHeaderTitleWrapper = styled('div', {
  name: 'PlpHeader',
  slot: 'StyledPLPHeaderTitleWrapper',
  shouldForwardProp: prop => prop !== 'isSticky' && prop !== 'isScrolled' && prop !== 'width' && prop !== 'withFilters',
})<{
  isSticky: boolean
  isScrolled: boolean
  width?: number
  withFilters?: boolean
}>(({ theme, isSticky, isScrolled, width, withFilters }) => ({
  position: isSticky ? 'fixed' : 'relative',
  top: 0,
  transform: `translateY(${isSticky && isScrolled ? '90px' : '0'})`,
  width: isSticky ? width : '100%',
  maxWidth: '100vw',
  background: theme.palette.background.light.primary,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  padding: theme.spacing(2, 4),
  transition: isSticky ? `transform ${theme.transitions.duration.standard}ms ease-in-out` : 'none',
  zIndex: Z_INDEX_LV2,
  boxShadow: theme.shadows[isSticky ? 1 : 0],
  marginBottom: withFilters ? 0 : theme.spacing(4),

  [theme.breakpoints.down('sm')]: {
    transform: `translateY(${isSticky && isScrolled ? '112px' : '0'})`,
  },

  [theme.breakpoints.between('sm', 'md')]: {
    transform: `translateY(${isSticky && isScrolled ? '100px' : '0'})`,
  },

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    alignItems: 'center',

    padding: theme.spacing(isSticky ? 2 : 0, 16),
    justifyContent: 'space-between',

    alignSelf: 'stretch',
    transform: `translateY(${isSticky && isScrolled ? '100px' : '0'})`,
  },
}))

export const StyledPLPHeaderTitleWrapperGV = styled('div', {
  name: 'PlpHeader',
  slot: 'WrapperTitle',
  shouldForwardProp: prop => prop !== 'isSticky' && prop !== 'isScrolled' && prop !== 'width' && prop !== 'withFilters',
})<{
  isSticky: boolean
  isScrolled: boolean
  width?: number
  withFilters?: boolean
}>(({ theme, isSticky, isScrolled, width, withFilters }) => ({
  position: isSticky ? 'fixed' : 'relative',
  top: 0,
  transform: `translateY(${isSticky && isScrolled ? '90px' : '0'})`,
  width: isSticky ? width : '100%',
  maxWidth: '100vw',
  background: theme.palette.background.light.primary,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  padding: theme.spacing(2, 4),
  transition: isSticky ? `transform ${theme.transitions.duration.standard}ms ease-in-out` : 'none',
  zIndex: Z_INDEX_LV2,
  boxShadow: theme.shadows[isSticky ? 1 : 0],
  marginBottom: withFilters ? 0 : theme.spacing(4),

  [theme.breakpoints.up('sm')]: {
    transform: `translateY(${isSticky && isScrolled ? '78px' : '0'})`,
  },

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(isSticky ? 2 : 0, 16),
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    transform: `translateY(${isSticky && isScrolled ? '96px' : '0'})`,
  },
}))
export const TitleContainer = styled('h1', {
  name: 'PlpHeader',
  slot: 'Title',
  shouldForwardProp: prop => prop !== 'isSticky',
})<{ isSticky: boolean }>(({ theme, isSticky }) => ({
  fontFamily: 'inherit',
  fontWeight: 'normal',
  color: theme.palette.text.dark.primary,
  marginTop: 0,
  fontSize: 24,

  lineHeight: 1.25,
  margin: '0',

  [theme.breakpoints.up('md')]: {
    fontFamily: 'inherit',
    fontWeight: 'normal',
    color: theme.palette.text.dark.primary,
    marginTop: 0,
    fontSize: 24,
    whiteSpace: 'nowrap',
    lineHeight: isSticky ? 1.4 : 1,
    '.dc_plp_header_skeleton': {
      span: {
        width: 700,
      },
    },
  },
}))

export const TitleContainerGV = styled('h1', {
  name: 'PlpHeader',
  slot: 'Title',
  shouldForwardProp: prop => prop !== 'isSticky',
})<{ isSticky: boolean }>(({ theme, isSticky }) => ({
  color: theme.palette.text.dark.primary,
  fontSize: 40,
  fontWeight: 700,
  lineHeight: '56px',
  margin: '0',
  fontFamily: '"Orgon","Helvetica Neue",arial,sans-serif',

  [theme.breakpoints.up('md')]: {
    color: theme.palette.text.dark.primary,
    fontSize: isSticky ? 32 : 40,
    fontWeight: 'normal',
    lineHeight: isSticky ? 1.4 : 1,
    '.dc_plp_header_skeleton': {
      span: {
        width: 700,
      },
    },
  },
}))

export const StyledResultsFor = styled(Typography, {
  name: 'PlpHeader',
  slot: 'ResultsFor',
})(() => ({
  fontSize: 12,
  lineHeight: 1.33,
}))

export const FilterCTASWrapper = styled('div', {
  name: 'PlpHeader',
  slot: 'FilterCTASWrapper',
  shouldForwardProp: prop => prop !== 'activeFilter',
})<{ activeFilter: boolean }>(({ theme, activeFilter }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flex: 1,
  padding: `0 ${theme.spacing(4)}`,
  gap: activeFilter ? 8 : 0,

  [theme.breakpoints.up('md')]: {
    padding: `0 ${theme.spacing(16)}`,
  },
}))

export const FilterCTASContainer = styled('div', {
  name: 'PlpHeader',
  slot: 'FilterCtaContainer',
})(({ theme }) => ({
  maxWidth: '90vw',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'left',
  gap: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    maxWidth: '100vw',
  },
}))

export const FiltersAppliedContainer = styled('div', {
  name: 'PlpHeader',
  slot: 'FiltersAppliedContainer',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  gap: theme.spacing(4),
  overflowX: 'auto',
  justifyContent: 'flex-start',
  minHeight: 34,

  [theme.breakpoints.up('md')]: {
    overflowX: 'initial',
    flexWrap: 'wrap',
    width: '70%',
  },

  ['::-webkit-scrollbar']: {
    display: 'none',
  },
}))

export const ClearAllContainer = styled('div', {
  name: 'PlpHeader',
  slot: 'ClearAllContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: theme.spacing(8),
}))

export const SortByWrapper = styled('div', {
  name: 'PlpHeader',
  slot: 'SortByWrapper',
  shouldForwardProp: prop => prop !== 'isVisible' && prop !== 'filtersApplied',
})<{ filtersApplied?: boolean }>(({ theme, filtersApplied }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  maxHeight: 34,
  width: '100%',

  ' > div:nth-of-type(1)': {
    position: 'absolute',
    zIndex: Z_INDEX_LV4,
  },

  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-end',
    padding: 0,
    marginLeft: 'auto',
    marginTop: filtersApplied ? 'auto' : 0,
    with: 'auto',
  },
}))

export const ResultText = styled('p', {
  name: 'PlpHeader',
  slot: 'ResultText',
})(({ theme }) => ({
  padding: '4px 14px 8px 0px',
  margin: 0,
  color: theme.palette.custom.gv.black,
  fontFamily:
    '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol',
  fontSize: '1rem !important',
  fontWeight: 400,
  lineHeight: 1.5,
}))

export const PLPFilterSkeletonWrapper = styled('span')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
})

export const ListingButtonsWrapper = styled('div', {
  name: 'PlpHeader',
  slot: 'ListingButtonsWrapper',
})(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4),
}))

export const ResultsNumberContainer = styled('div', {
  name: 'PlpHeader',
  slot: 'ResultsNumberContainer',
})(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
}))

export const SortByCTA = styled(Pill, {
  name: 'PlpHeader',
  slot: 'SortByCTA',
})(({ theme }) => ({
  marginRight: theme.spacing(7.5),
  [theme.breakpoints.up('sm')]: {
    marginRight: theme.spacing(2),
  },
  marginTop: 0,
}))

export const SortByCategory = styled('div', {
  name: 'PlpHeader',
  slot: 'SortByCategory',
})(({ theme }) => ({
  marginTop: 0,
  color: theme.palette.custom.gv.blue,
  '&:hover': {
    color: theme.palette.custom.gv.blue,
  },
}))

export const SortByCTAGV = styled(Pill, {
  name: 'PlpHeader',
  slot: 'SortByCTA',
})(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    marginRight: theme.spacing(2),
  },
  border: '0 !important',
  backgroundColor: 'transparent !important',
  fontFamily:
    '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;',
  fontSize: 16,
  fontWeight: 600,
}))

export const SortByCTAMobile = styled(Pill, {
  name: 'PlpHeader',
  slot: 'SortByCTA',
})(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.background.light.secondary,
  },

  [theme.breakpoints.up('sm')]: {
    marginRight: theme.spacing(2),
  },
}))

export const SortByCTASelectedOption = styled(Pill, {
  name: 'PlpHeader',
  slot: 'SortByCTASelectedOption',
})(({ theme }) => ({
  color: theme.palette.custom.gv.blue,
}))

export const StyledFade = styled(Fade, {
  name: 'PlpHeader',
  slot: 'Fade',
})(() => ({
  maxHeight: 32,
}))

export const SortByContainer = styled('div', {
  name: 'PlpHeader',
  slot: 'SortByContainer',
})(({ theme }) => ({
  padding: theme.spacing(3, 4, 5, 4),
  border: 'none',
}))

export const SortByContent = styled('div', {
  name: 'PlpHeader',
  slot: 'SortByContent',
  shouldForwardProp: prop => prop !== 'isVisible' && prop !== 'filtersApplied',
})<{
  isVisible: boolean
  filtersApplied: boolean
}>(({ isVisible, filtersApplied, theme }) => ({
  position: 'static',
  marginTop: theme.spacing(13),
  zIndex: filtersApplied ? Z_INDEX_LV4 : Z_INDEX_LV3,
  width: '100%',
  display: isVisible ? 'block' : 'none',
  boxShadow: theme.shadows[1],
  backgroundColor: theme.palette.background.light.secondary,
  '& .MuiFormGroup-root': {
    gap: theme.spacing(4),
    '& .MuiRadio-root': {
      padding: theme.spacing(0.5, 2, 0, 4),
    },
  },
  '& .MuiFormGroup-root span': {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.43,
    color: theme.palette.text.dark.primary,
  },
  '& .MuiFormGroup-root label': {
    marginBottom: 0,
  },
  [theme.breakpoints.up('sm')]: {
    width: 278,
    right: filtersApplied ? theme.spacing(16) : theme.spacing(20),
    top: filtersApplied ? 100 : 200,
  },
}))

export const FilterCTA = styled(Pill, {
  name: 'PlpHeader',
  slot: 'FilterCTA',
})(({ theme }) => ({
  '&:hover': {
    background: theme.palette.background.dark.tertiary,
  },
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(7.5),
  },
  [theme.breakpoints.up('md')]: {
    marginLeft: theme.spacing(2),
  },
}))

export const ClearAllLink = styled(ButtonAsLink, {
  name: 'PlpHeader',
  slot: 'ClearAllLink',
})(() => ({
  fontSize: 14,
  fontWeight: 600,
  lineHeight: 1.43,
  height: 20,
}))

export const StyledPLPFiltersBarButtonsWrapper = styled('div', {
  name: 'PlpHeader',
  slot: 'PLPFiltersBarButtonsWrapper',
})(({ theme }) => ({
  display: 'flex',
  flex: 1,
  marginBottom: theme.spacing(8),
}))

export const StyledPLPFiltersBar = styled('div', {
  name: 'PlpHeader',
  slot: 'FiltersBar',
  shouldForwardProp: prop => prop !== 'isStuck' && prop !== 'isFiltersShown' && prop !== 'isMobileContainer',
})<{ isStuck: boolean; isFiltersShown?: boolean; isMobileContainer?: boolean }>(
  ({ isStuck, isFiltersShown, isMobileContainer, theme }) => ({
    overflow: 'visible',
    position: 'sticky',
    top: -1,
    display: 'flex',
    alignItems: 'center',
    paddingTop:
      !isFiltersShown && isMobileContainer
        ? theme.spacing(8)
        : isFiltersShown && isMobileContainer
          ? theme.spacing(2)
          : theme.spacing(0),
    whiteSpace: 'nowrap',
    background: theme.palette.background.light.primary,
    marginTop: 0,
    transition: '300ms',
    zIndex: Z_INDEX_LV1,
    height: !isFiltersShown && isMobileContainer ? '10px' : 'auto',

    '.first-btn-skeleton': {
      marginRight: theme.spacing(4),
    },

    boxShadow: isMobileContainer ? (!isFiltersShown ? 'none' : theme.shadows[isStuck ? 1 : 0]) : 'none',

    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
      paddingTop: theme.spacing(0),
    },
  })
)

export const StyledCompactListingButton = styled(IconButton, {
  name: 'PlpHeader',
  slot: 'CompactListingButton',
})(() => ({}))

export const StyledFullWidthListingButton = styled(IconButton, {
  name: 'PlpHeader',
  slot: 'FullWidthListingButton',
})(() => ({}))

export const StyledPLPSeparator = styled('div', {
  name: 'PlpHeader',
  slot: 'PLPSeparator',
})(({ theme }) => ({
  width: 'auto',
  height: 1,
  alignSelf: 'stretch',
  flexGrow: 0,
  backgroundColor: theme.palette.background.light.tertiary,
  margin: theme.spacing(2, 4, 0, 0),

  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(4, 16, 4, 16),
  },
}))

export const StyledPLPTitleSeparator = styled('div', {
  name: 'PlpHeader',
  slot: 'PLPTitleSeparator',
})(({ theme }) => ({
  width: 'auto',
  height: 1,
  alignSelf: 'stretch',
  flexGrow: 0,
  backgroundColor: theme.palette.background.light.tertiary,
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}))

export const SquaredFilterPill = styled(Pill, {
  name: 'PlpHeader',
  slot: 'SquaredFilterPill',
  shouldForwardProp: prop => prop !== 'selected',
})<{
  selected?: true | false | undefined
}>(({ selected, theme }) => ({
  borderRadius: 0,
  border: `1px solid ${theme.palette.background.dark.secondary}`,
  background: selected ? theme.palette.background.dark.secondary : theme.palette.background.light.primary,
  color: selected ? theme.palette.custom.white : theme.palette.custom.black,

  '&:hover': {
    background: selected ? theme.palette.background.dark.secondary : theme.palette.background.dark.quaternary,
    border: `1px solid ${theme.palette.background.dark.secondary}`,
    color: selected ? theme.palette.custom.white : theme.palette.custom.black,
  },
}))

export const SquaredFilterPillWrapper = styled('div', {
  name: 'PlpHeader',
  slot: 'SquaredFilterPillWrapper',
})(() => ({
  position: 'relative',
}))

export const StyledFilterResultWrapper = styled('div', {
  name: 'PlpHeader',
  slot: 'FilterResultWrapper',
})(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
}))

export const StyledFilterWrapper = styled('div', {
  name: 'PlpHeader',
  slot: 'FilterWrapper',
})(({ theme }) => ({
  display: 'flex',

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },

  [theme.breakpoints.up('md')]: {
    marginLeft: '16px',
  },
}))

export const StyledScrollToTopButton = styled(IconButton, {
  name: 'PlpHeader',
  slot: 'ScrollToTopButton',
})(({ theme }) => ({
  width: 32,
  height: 32,
  marginLeft: theme.spacing(4),
  backgroundColor: theme.palette.background.light.secondary,
  cursor: 'pointer',

  '.MuiSvgIcon-root': {
    width: 16,
    height: 16,
  },
}))

export const StyledPLPOffersFiltersWrapper = styled('div', {
  name: 'PlpHeader',
  slot: 'PLPOffersFiltersWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexGrow: 0,
  justifyContent: 'flex-start',
  flexDirection: 'row',
  padding: theme.spacing(4, 16, 0, 0),
  columnGap: theme.spacing(4),
  whiteSpace: 'nowrap',
  overflowX: 'auto',
  minHeight: 34,

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 16),
  },

  ['::-webkit-scrollbar']: {
    display: 'none',
  },
}))

export const StyledFacetFilterContainer = styled('div', {
  name: 'PlpHeader',
  slot: 'StyledFacetFilterConatainer',
})(() => ({
  // height: 100,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: 8,
}))

export const StyledMenuItem = styled(MenuItem, {
  name: 'PlpHeader',
  slot: 'StyledMenuItem',
})(({ theme }) => ({
  width: 250,
  padding: '5px 0',
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: theme.palette.custom.white,
  },
}))

export const StyledMenuWrapper = styled(Menu, {
  name: 'PlpHeader',
  slot: 'StyledMenuWrapper',
})(({ theme }) => ({
  '.MuiMenu-paper': {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    // boxShadow: theme.shadows[1],
    padding: theme.spacing(4),
  },
  '.MuiMenu-list': {
    padding: 0,
  },
}))

export const StyledMenuContent = styled('div', {
  name: 'PlpHeader',
  slot: 'StyledMenuWrapper',
})(() => ({
  maxHeight: 200,
  overflow: 'auto',
}))

export const StyledMenuItemHeader = styled(StyledMenuItem, {
  name: 'PlpHeader',
  slot: 'StyledMenuWrapper',
})(({ theme }) => ({
  padding: '6px 0',
  fontSize: '0.9em',
  color: theme.palette.color.grey,
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
}))

export const DeleteLink = styled(ButtonAsLink, {
  name: 'PlpHeader',
  slot: 'DeleteLink',
})(() => ({
  fontSize: 14,
  fontWeight: 600,
  lineHeight: 1.43,
}))

export const StyledCheckbox = styled(Checkbox, {
  name: 'PlpHeader',
  slot: 'StyledCheckbox',
})(({ theme }) => ({
  padding: 0,
  paddingRight: theme.spacing(2),
  '& .MuiSvgIcon-root': {
    fontSize: 28,
    '& :focus': {
      border: '3px solid #1976d275',
      borderRadius: 5,
      boxShadow: `0 0 0 4px hsla(0,0%,100%,.7),0 0 0 4px ${theme.palette.primary}`,
    },
  },
}))
