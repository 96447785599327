import { useEffect, useCallback } from 'react'
import { getSessionItem, SessionPrefix, setSessionItem } from '../sessionStorage'
import { APP_MAIN_CONTAINER_ID } from '@constants/ui'

const scrollToPos = (x: number, y: number, scrollContainer: HTMLElement) => {
  const scrollTimeout = setTimeout(() => {
    scrollContainer.scrollTo(x, y)
    clearTimeout(scrollTimeout)
  }, 0)
}

const saveScrollPosition = (url: string, scrollContainer: HTMLElement) => {
  const scrollPos = { x: scrollContainer.scrollLeft, y: scrollContainer.scrollTop }
  setSessionItem({ key: url, value: scrollPos, prefix: SessionPrefix.SCROLL_PAGE })
}

const restoreScrollPosition = (url: string, scrollContainer: HTMLElement) => {
  const storedScrollPos = getSessionItem({ key: url, prefix: SessionPrefix.SCROLL_PAGE })
  if (storedScrollPos) {
    scrollToPos(storedScrollPos.x, storedScrollPos.y, scrollContainer)
  }
}

const useScrollRestoration = (isReady: boolean, pathname: string | undefined) => {
  const handleScroll = useCallback(() => {
    const appMainContainer = document.getElementById(APP_MAIN_CONTAINER_ID)
    if (pathname && appMainContainer) saveScrollPosition(pathname, appMainContainer)
  }, [pathname])

  useEffect(() => {
    const appMainContainer = document.getElementById(APP_MAIN_CONTAINER_ID)

    if (isReady && pathname && appMainContainer) restoreScrollPosition(pathname, appMainContainer)

    appMainContainer?.addEventListener('scroll', handleScroll)

    return () => {
      appMainContainer?.removeEventListener('scroll', handleScroll)
    }
  }, [isReady, pathname, handleScroll])
}

export default useScrollRestoration
